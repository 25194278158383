<template>
  <div>
    <div class="px-5 py-2">
      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="fullPage"></loading>

      <div class="row justify-content-md-center">
        <!--   Search Bar   -->
        <div class="col-12 col-md-12 mt-5 mb-3">
            <search-bar v-model="filterData" v-on:search="onSearch" :equipment-list="equipmentList"/>
        </div>
      </div>
    </div>

    <div class="abaixo px-5 py-2" style="background-color: #fff">
      <div class="row custom-row justify-content-md-center">
        <div class="col-12">
            <div class="row">
              <!-- Content -->
              <div class="col-md-12">
                <!-- Sort Order -->
                <div class="row" v-if="tariffOptions.length > 0">
                  <div class="col-md-8 price-div">
                    <div v-if="showResultsFor">
                    Mostrando resultados para:
                      {{ searchTerms.origin.join(', ') }}
                      {{ searchTerms.destination.join(', ') }}
                    </div>
                  </div>

                  <div class="col-md-4 text-right px-0 mx-0">
                    <div class="">
                      <label class="small p-0 m-0 cinza">{{  $t('form.proposal.sort by')  }}</label>
                      <div>
                        <div class="dropdown p-0 m-0">
                          <button class="btn btn-link dropdown-toggle p-0 m-0" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <small><strong>{{ filterData.sortBy.nome }}</strong></small>
                          </button>
                          <div class="dropdown-menu custom-dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <label class="w-100 px-4 pt-2">Ordenar por</label>
                            <a class="dropdown-item custom-dropdown-item" v-for="(sortOrder) in sortOrderList"
                              v-bind:key="sortOrder.id" v-on:click="selectSortOrder(sortOrder)">
                              {{ sortOrder.nome }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Search Result -->
            <div class="row cinza" v-if="tariffOptions.length > 0">
              <search-result v-for="(tariffOption, index) in tariffOptions" :tariffOption="tariffOption"
                :availableCupom="availableCupom"
                v-bind:key="index" v-on:option-seleted="onOptionSelected" />
            </div>
            <div class="row" v-else-if="filtered">
              <!-- Nenhuma opção encontrada -->
              <div class="col-10 proposal-no-options pb-5" v-if="resultType === 1">
                <div class="float-left" style="width: 100px;">
                  <img src="/static/images/search_icon.png" alt="">
                </div>
                <div class="float-left text-content">
                  <h1 class="title">Opa! Não possuímos essa Origem X Destino por aqui.</h1>
                  <div>
                    Clique no botão abaixo para solicitar uma proposta manual do nosso time Comercial. Te contatamos logo, logo!
                  </div>
                  <button v-on:click="sendNoOption(filtered)" class="btn btn-sm btn-primary btn-fill px-4 py-2">Solicitar Proposta</button>
                </div>
                <div class="clearfix"></div>
              </div>

              <!-- Nenhuma opção encontrada na data específicada -->
              <div class="col-10 proposal-no-options pb-5" v-if="resultType === 2 || resultType === 4">
                <div class="float-left" style="width: 100px;">
                  <img src="/static/images/search_icon.png" alt="">
                </div>
                <div class="float-left text-content">
                  <h1 class="title">Ops! Não encontrei valores para a data {{ $util.formatDate(searchDate) }}.</h1>
                  <div class="mb-4">Tenho fretes com validade até o dia {{ $util.formatDate(maxValidity) }}. <a class="card-link btn-link" v-on:click="onSearchSuggested" >CLIQUE AQUI</a> para ver esses valores</div>
<!--                  <div class="mb-0" v-for="(option, index) in searchBy" v-bind:key="index">-->
<!--                    Opção {{ index+1 }}: {{ $util.formatDateWritten(option.Data_Inicio_Vigencia) }} à {{ $util.formatDateWritten(option.Data_Validade) }}-->
<!--                  </div>-->
                  <div class="mb-3">
                    Caso queira manter a previsão de embarque para o dia {{ $util.formatDate(searchDate) }}, um de nossos vendedores te enviará uma proposta comercial por e-mail.
                  </div>
                  <button v-on:click="sendNoOption(filtered)" class="btn btn-sm btn-primary btn-fill px-4 py-2">Manter data e solicitar Proposta</button>
                </div>
                <div class="clearfix"></div>
              </div>

            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Form
import SearchBar from './SearchBar'
import SearchResult from './SearchResult'

// Services
import EquipmentService from '@/services/EquipmentService'
import TariffService from '@/services/TariffService'
import moment from 'moment'
import ProposalService from '@/services/ProposalService'

export default {
  name: 'ProposalSearch',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.proposal') + ' - %s'
    }
  },
  data () {
    return {
      filtered: false,
      resultType: 1,
      searchBy: [],
      searchTerms: {
        origin: [],
        destination: []
      },
      maxValidity: null,
      searchDate: null,
      isLoading: true,
      fullPage: true,
      equipmentList: [],
      sortOrderList: [
        {
          id: 1,
          nome: 'Preço mais baixo'
        },
        {
          id: 2,
          nome: 'Preço mais alto'
        },
        {
          id: 3,
          nome: 'Free Time maior'
        },
        {
          id: 4,
          nome: 'Free Time menor'
        },
        {
          id: 5,
          nome: 'Transit time menor'
        },
        {
          id: 6,
          nome: 'Transit time maior'
        },
        {
          id: 7,
          nome: 'Validade de embarque menor'
        },
        {
          id: 8,
          nome: 'Validade de embarque maior'
        }
      ],
      // filterData
      filterData: {
        operation: {
          id: 2,
          nome: 'IMPORT'
        },
        modality: {
          id: 2,
          nome: 'Marítimo - FCL'
        },
        incoterm: {
          id: 2,
          nome: 'FOB'
        },
        commoditie: {
          id: null,
          nome: null
        },
        origin: {
          id: null,
          nome: null
        },
        destination: {
          id: null,
          nome: null
        },
        estimatedDate: null,
        equipments: [],
        cbm: null,
        peso_bruto: null,
        showShipowner: null,
        sortBy: {
          id: 1,
          nome: 'Preço mais baixo'
        }
      },
      tariffOptions: [],
      availableCupom: null
    }
  },
  components: {
    SearchResult,
    Loading,
    SearchBar
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  // Apply filters to local filter
  created () {
    let _this = this
    let params = _this.$route.params

    // Se nenhum filtro aplicado na tela anterior, carrega dados dos cookies
    if (params && Object.keys(params).length === 0 && params.constructor === Object) {
      let previousFilters = global.instanceApp.$Cookie.get('_proposal_last_search_')
      if (previousFilters !== undefined) {
        let today = moment()
        _this.filterData = JSON.parse(previousFilters)

        let selectedDate = moment(_this.filterData.estimatedDate)

        let days = selectedDate.diff(today, 'days')

        if (days < 1) {
          _this.filterData.estimatedDate = today.add(1, 'days').format()
        }
      }
    }

    /** @TODO: Load basilares data */
    _this.isLoading = true

    _this.equipmentLoadOptions().then(res => {
      _this.equipmentList = res
      // Filtered on previous page
      if (params && Object.keys(params).length > 0 && params.constructor === Object) {
        _this.filterData = _this.$route.params
        _this.onSearch(_this.filterData)
      }
    }).finally(
      _this.isLoading = false
    )
  },
  computed: {
    showResultsFor () {
      if (this.searchTerms.origin.length > 0) {
        return true
      }

      if (this.searchTerms.destination.length > 0) {
        return true
      }

      return false
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
    sendNoOption (searchInfo) {
      let _this = this
      _this.isLoading = true
      let formData = {}

      formData.searchInfo = searchInfo

      ProposalService.postSendNoOptions(formData).then((res) => {
        global.instanceApp.$Cookie.remove('_proposal_last_search_')
        _this.$alertSwal.modalAlert('', 'Solicitação de proposta enviada', 'success', null, {
          showConfirmButton: false
        })

        setTimeout(function () {
          _this.$router.push({ name: 'ProposalIndex' })
        }, 2000)
      }).finally(() => {
        _this.isLoading = false
      })
    },
    onOptionSelected (selectedOption) {
      this.isLoading = true
      let formData = {
        adicionais: {
          seguro: {}
        }
      }

      // Cria o rascunho com os dados iniciais
      if (selectedOption.carga && selectedOption.carga.seguroFreteMercadoria) {
        formData.adicionais.seguro.freteMercadoria = selectedOption.carga.seguroFreteMercadoria
      }

      if (selectedOption.cupom && selectedOption.cupom.id != null) {
        formData.cupom = {
          id: selectedOption.cupom.id
        }
      }

      formData.id_tarifario_maritimo = selectedOption.id_tarifario_maritimo
      formData.transitTime = selectedOption.transit_time
      formData.idSysCotacaoLog = this.filtered
      formData.incoterm = selectedOption.incoterm
      formData.peso_bruto = selectedOption.peso_bruto
      formData.cbm = selectedOption.cbm
      formData.data_embarque = selectedOption.data_embarque
      formData.origem = selectedOption.pol
      formData.adicionais.seguro.valor_frete = selectedOption.valor_frete
      formData.cliente = selectedOption.id_cliente
      formData.destino = selectedOption.pod
      formData.special = selectedOption.special
      formData.brandScac = selectedOption.brandScac
      formData.mercadoria = selectedOption.mercadoria
      formData.modalidadeProcesso = selectedOption.modalidade_processo
      formData.tipoOperacao = selectedOption.tipo_operacao
      formData.tipoCarga = selectedOption.tipo_carga
      formData.equipamentos = selectedOption.equipamentos
      formData.previsao_embarque = selectedOption.previsao_embarque
      formData.taxas = selectedOption.taxas
      formData.total = selectedOption.total
      formData.rota = selectedOption.rota
      formData.numero_contrato = selectedOption.numero_contrato
      formData.servico_com_transbordo = selectedOption.servico_com_transbordo

      ProposalService.postNewProposal(formData).then((res) => {
        this.isLoading = false
        this.$router.push({ name: 'ProposalCreate', params: { id: res.data.data.id } })
      })
    },
    selectSortOrder (sortBy) {
      this.filterData.sortBy = sortBy

      this.onSearch(this.value)
    },
    equipmentLoadOptions () {
      let _this = this

      let promise = new Promise((resolve, reject) => {
        let filters = {
          'modality': _this.filterData.modality.id,
          'quotations': true
        }

        EquipmentService.getEquipments(filters).then(res => {
          let items = []

          for (let i in res.data.data) {
            let item = res.data.data[i]

            item.quantity = 0

            for (let sI in _this.filterData.equipments) {
              let selected = _this.filterData.equipments[sI]

              if (selected.id === item.id) {
                item.quantity = selected.quantity
                break
              }
            }
            items.push(item)
          }
          resolve(items)
        }).catch((error) => {
          reject(error)
        })
      })

      return promise
    },
    onSearchSuggested () {
      let _this = this

      _this.filterData.estimatedDate = _this.maxValidity
      _this.onSearch()
    },
    onSearch (value) {
      let _this = this
      _this.filtered = false
      _this.isLoading = true
      _this.resultType = 1
      _this.searchBy = []

      let isFcl = _this.filterData.modality.id === 2

      // Save last filter
      global.instanceApp.$Cookie.set('_proposal_last_search_', JSON.stringify(_this.filterData))

      let filters = {
        operation: _this.filterData.operation.id,
        modality: _this.filterData.modality.id,
        commoditie: _this.filterData.commoditie.id,
        origin: _this.filterData.origin.id,
        destination: _this.filterData.destination.id,
        estimatedDate: _this.filterData.estimatedDate,
        sortBy: _this.filterData.sortBy.id,
        incoterm: _this.filterData.incoterm.id
      }

      if (isFcl) {
        filters.equipments = _this.filterData.equipments.map((equipment) => {
          return {
            id: equipment.id,
            descricao: equipment.descricao,
            quantity: equipment.quantity
          }
        })
      } else {
        filters.cbm = _this.$util.getNumberFromFormated(_this.filterData.cbm)
        filters.peso_bruto = _this.$util.getNumberFromFormated(_this.filterData.peso_bruto)
      }

      if (_this.filterData.customer && _this.filterData.customer.id) {
        filters.customer = _this.filterData.customer.id
      }

      if (isFcl) {
        TariffService.getTariffs(filters).then((res) => {
          _this.tariffOptions = res.data.data
          _this.availableCupom = (res.data.availableCupom !== false) ? res.data.availableCupom : null
          _this.filtered = res.data.searchInfo
          _this.resultType = res.data.resultType
          _this.searchBy = res.data.searchBy
          _this.searchDate = res.data.searchDate
          _this.maxValidity = res.data.maxValidity
          _this.searchTerms = res.data.searchTerms
        }).finally(() => {
          _this.isLoading = false
        })
      } else {
        TariffService.getLclTariffs(filters).then((res) => {
          _this.tariffOptions = res.data.data
          _this.availableCupom = (res.data.availableCupom !== false) ? res.data.availableCupom : null
          _this.filtered = res.data.searchInfo
          _this.resultType = res.data.resultType
          _this.searchBy = res.data.searchBy
          _this.searchDate = res.data.searchDate
          _this.maxValidity = res.data.maxValidity
          _this.searchTerms = res.data.searchTerms
        }).finally(() => {
          _this.isLoading = false
        })
      }
    }
  }
}
</script>

<style scoped>
.custom-dropdown-menu {
  border: 1px solid #ccc !important;
  border-radius: 7px !important;
  box-sizing: border-box !important;
}

.custom-dropdown-item {
  color: gray !important;
}

.price-div {
  margin: auto !important;
}

.cinza {
  color: gray;
}

.proposal-no-options {
  margin-top: 30px;
}

.proposal-no-options div {
  color: #717274;
  font-size: 98%;
  margin-bottom: 20px;

}

.proposal-no-options .title {
  color: #3d3d3d;
  font-size: 160%;
}

.float-left.text-content {
  width: calc(90% - 100px);
}

</style>
