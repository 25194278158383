<template>
  <div class="col-12 border search-card m-2 p-4" v-if="tariffOption.total.length > 0">
    <div v-if="isSuperAdmin && tariffOption.special" class="integration-icon"><i class="font-26 fa fa-puzzle-piece"></i></div>
    <div class="row custom-row">
      <!-- Descrição -->
<!--      <div class="col-lg-12 col-md-12 above debug">-->
<!--        <pre>{{ tariffOption['id_tarifario_maritimo']}}</pre>-->
<!--      </div>-->
      <!-- Origem e Destino-->
      <div class="col-lg-3 col-md-6 above">
        <label class="small p-0 m-0">{{  $t('form.proposal.origin')  }} > {{  $t('form.proposal.destination')  }}</label>
        <div class="spotlight pb-2">{{ tariffOption.pol.nome }}{{ (tariffOption.pol.pais) ? (', ' + tariffOption.pol.pais) : '' }}<i class="ft-arrow-right"></i> {{ tariffOption.pod.nome }}{{ (tariffOption.pod.pais) ? (', ' + tariffOption.pod.pais) : '' }}</div>
        <label class="small" v-show="!opened" v-html="$t('form.proposal.valido_de_ate', { from: $util.formatDateWritten(tariffOption.data_inicio_vigencia), to: $util.formatDateWritten(tariffOption.data_validade)})"></label>
        <!-- Se Transbordo -->
        <div class="small " v-if="tariffOption.servico_com_transbordo"><strong>* Serviço com transbordo</strong></div>
      </div>

      <!-- Transit Time-->
      <div class="col-lg-2 col-md-3 col-sm-6 between above">
        <label class="small">{{  $t('form.proposal.trasitTime')  }}</label>
        <div class="small spotlight" v-if="getTransitTime">{{ getTransitTime }}</div>
        <div class="small spotlight" v-else>
          <span><i class="ft-alert-circle"></i><md-tooltip md-direction="right">Sem informações no momento!</md-tooltip></span>
        </div>
      </div>

      <!-- Free Time-->
      <div class="col-lg-1 col-md-3 col-sm-6 between above">
        <div v-if="tariffOption.equipamentos">
          <label class="small">{{  $t('form.proposal.freeTime')  }}</label>
          <div class="small spotlight">{{ getFreeTime(tariffOption.equipamentos) }}</div>
        </div>
      </div>

      <!-- Cargas-->
      <div class="col-lg-2 col-md-3 col-sm-6 between bellow above">
        <div v-if="tariffOption.equipamentos">
          <label class="small">{{  $t('form.proposal.equipamento')  }}</label>
          <div class="small spotlight">{{ getEquipments(tariffOption.equipamentos) }}</div>
        </div>
      </div>

      <!-- Labels-->
      <div class="col-lg-1 col-md-3 col-sm-6 between bellow above bag-recomend p-0 m-0">
        <div v-if="tariffOption.menor_preco">
          <span class="badge badge-success align-bottom">Menor Preço</span>
        </div>
      </div>

      <!-- Preço total -->
      <div class="col-lg-2 col-md-3 col-sm-6 text-right between bellow above" v-show="!opened">
        <label class="small">{{ $t('form.proposal.preco_frete') }}</label>
        <div>
          <span class="currency spotlight"><span>{{ tariffOption.moeda_frete }}</span> {{ $util.numberFormat(tariffOption.valor_frete, 2, ',', '.') }}</span>
        </div>
      </div>

      <div class="col-lg-1 col-md-3 col-sm-6 between bellow bag-recomend">
        <div class="select icon text-center pull-right btn-tiny" v-on:click="showPriceDetails()">
          <i v-if="opened" class="ft-chevron-up"></i>
          <i v-else class="ft-chevron-down"></i>
        </div>
        <!-- <button class="mt-3 btn btn-primary pull-right" v-on:click="$emit('option-seleted', tariffOption)">-->
        <!-- <i class="ft-chevron-up"></i>-->
        <!-- <i class="ft-chevron-down"></i>-->
        <!-- </button>-->
      </div>

    </div>

      <!-- Detalhes -->
      <price-details :tariff="tariffOption" v-if="opened" v-on:option-seleted="onOptionSelected" :availableCupom="availableCupom" />
  </div>
</template>

<script>
// @ is an alias to /src

// Modals
import PriceDetails from '@/views/Proposal/PriceDetails'

export default {
  name: 'SearchResult',
  data () {
    return {
      opened: false,
      operationList: [
        {
          id: 1,
          nome: 'Exportação'
        },
        {
          id: 2,
          nome: 'Importação'
        }
      ],
      originList: [],
      destinationList: [],
      commoditieList: [],
      modalityList: [
        {
          id: 2,
          nome: 'Marítimo - FCL'
        },
        {
          id: 4,
          nome: 'Marítimo - LCL'
        }
      ]
    }
  },
  components: {
    PriceDetails
  },
  computed: {
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    getTransitTime () {
      let _this = this

      if (_this.tariffOption.transit_time) {
        return _this.tariffOption.transit_time + ' Dias'
      }

      return ''
    },
    activeConditions () {
      let _this = this

      // Tipos: 1: Texto | 2: Numerico | 3: Data | 4: Opções
      let conditionList = [
        {
          id: 1,
          nome: 'Igual à'
        },
        {
          id: 2,
          nome: 'Diferente de'
        },
        {
          id: 3,
          nome: 'Maior ou igual à',
          tipos: [2, 3]
        },
        {
          id: 4,
          nome: 'Menor ou igual à',
          tipos: [2, 3]
        },
        {
          id: 5,
          nome: 'Contém',
          tipos: [1]
        }
      ]

      return conditionList.filter(function (item) {
        // Se não tiver o atributo é válido para todos
        if (!item.hasOwnProperty('tipos')) {
          return true
        }

        if (_this.value) {
          return item.tipos.includes(_this.value.fieldA.tipo)
        }
      })
    },
    valueFields () {
      let _this = this

      let options = []

      if (_this.value.fieldA && _this.value.fieldA.tipo === 7) {
        options.push({ nome: 'Sim' })
        options.push({ nome: 'Não' })
      }

      return options
    },
    activeFields () {
      let _this = this

      let optionsFields = _this.fields.filter(function (item) {
        // Se não selecionado o campo A não dá opções
        if (!_this.value.fieldA || !_this.value.fieldA.id) {
          return false
        }

        // Se campos forem do mesmo tipo pode selecionar
        if (item.tipo === _this.value.fieldA.tipo) {
          if (item.codigo === 'dia_mes') {
            if (_this.value.condition && (_this.value.condition.id === 1 || _this.value.condition.id === 2)) {
              return true
            } else {
              return false
            }
          } else if (item.codigo === 'nulo') {
            if (_this.value.condition && (_this.value.condition.id === 1 || _this.value.condition.id === 2)) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        }
      })

      return optionsFields
    },
    fieldsList () {
      let _this = this

      let optionsFields = _this.fields.filter(function (item) {
        // Se não selecionado o campo A não dá opções
        if (item.codigo === 'nulo' || item.codigo === 'hoje' || item.codigo === 'dia_mes') {
          return false
        } else {
          return true
        }
      })

      return optionsFields
    }
  },
  props: {
    fields: {
      type: [Array],
      description: 'Field available to select',
      default: (val) => {
        val = []
        return val
      }
    },
    tariffOption: {
      type: Object,
      required: true
    },
    availableCupom: {
      type: Object,
      required: false
    }
  },
  methods: {
    onOptionSelected () {
      this.$emit('option-seleted', this.tariffOption)
    },
    getFreeTime (equipamentos) {
      let freeTime = []

      for (let i = 0; i < equipamentos.length; i++) {
        let equipamento = equipamentos[i]

        if (equipamento.free_time_origem) {
          if (!freeTime.includes(equipamento.free_time_origem)) {
            freeTime.push(equipamento.free_time_origem)
          }
        }

        if (equipamento.free_time_destino) {
          if (!freeTime.includes(equipamento.free_time_destino)) {
            freeTime.push(equipamento.free_time_destino)
          }
        }
      }

      return freeTime.join(' / ') + ' DIAS'
    },
    getEquipments (equipamentos) {
      let descricao = []

      for (let i = 0; i < equipamentos.length; i++) {
        let equipamento = equipamentos[i]
        descricao.push(equipamento.quantidade + ' X ' + equipamento.descricao)
      }

      return descricao.join(' / ')
    },
    applyTranslate (val) {
      // Se não informado retorna vazio
      if (!val) {
        return ''
      }

      let translated = this.$i18n.t('taskCampaign.fields.' + val)

      if (translated.includes('taskCampaign.fields.')) {
        return val
      }

      return translated
    },
    showPriceDetails () {
      this.opened = !this.opened
    }
  },
  created () {
  }
}
</script>

<style scoped>
.integration-icon {
  position: absolute;
  right: 18px;
  top:10px;
}

.swal2-popup {
  width: inherit !important;
}

.card-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.md-form-control {
  height: 30px;
}

.icon-gray:before {
  color: #A29779;
  background-color: #F2F1ED;
}

.debug {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  background-color: #E8E8E8;
}

.debug {
  border-left-color: #aa6708;
}

.search-card {
  -webkit-border-radius: 1.6rem;
  border-radius: 1.6rem;
  -webkit-border-radius: 1.6rem;
  border-radius: 1.6rem;
}

.select.icon {
  height: 30px;
  width: 30px;
  padding-top: 6px;
  color: #fff;
  background-color: #84754e;
  border: 1px solid;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

.between {
  /* background-color: fuchsia; */
  margin: auto;
  text-align: center;
  height: 100%;
}

.spotlight {
  color: #84754e;
  font-weight: bold;
}

.bag-recomend {
  float: none !important;
  margin: auto !important;
}

@media (max-width: 992px) {
  .bellow {
    text-align: center !important;
    margin: 15px 0px 0px 0px;
  }

  .btn-tiny {
    float: none !important;
    margin: auto;
  }

  .above {
    margin-bottom: 2vh;;
  }
}
</style>
