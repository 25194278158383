import { render, staticRenderFns } from "./PriceDetails.vue?vue&type=template&id=4d66f3b1&scoped=true&"
import script from "./PriceDetails.vue?vue&type=script&lang=js&"
export * from "./PriceDetails.vue?vue&type=script&lang=js&"
import style0 from "./PriceDetails.vue?vue&type=style&index=0&id=4d66f3b1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d66f3b1",
  null
  
)

export default component.exports