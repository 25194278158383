<template>
  <form data-vv-scope="proposalSearchBar" method="post">
    <div class="row custom-row">
      <div class="col-12 px-2 mt-3">
        <h2 class="elegant-title">{{  $t('sidebar.new proposal')  }}</h2>
      </div>
    </div>

    <div class="row custom-row">
      <!--  Operação  -->
      <div class="col-lg-2 col-md-6 form-group px-2">
        <dual-switch
          v-bind:options="operationOptions"
          v-bind:selectedItem="value.operation"
          v-on:set-option="setOperation" />
        <transition name="slide" mode="">
          <small v-if="errors.first('value.origin') || errors.first('value.destination')"
            class="royalc-error-field">&nbsp;</small>
        </transition>
      </div>

      <!--  Modalidade  -->
      <div class="col-lg-2 col-md-6 form-group px-2">
        <label class="small grey-label">{{  $t('form.proposal.modality')  }}
          <span><i class="ft-alert-circle"></i><md-tooltip md-direction="right">Em breve mais opções estarão disponíveis</md-tooltip></span>
        </label>
        <v-select
          label="nome" name="value.modality" class="grey-field" :clearable="false" :searchable="false"
          @input="onSelectModality"
          v-model="value.modality" :options="modalityList"
          v-validate="{ required_object: true }"
          :disabled="!allowLcl"
        >
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.nome }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.nome }}
            </div>
          </template>
        </v-select>
        <transition name="slide" mode="">
          <small v-if="errors.first('value.modality')" class="royalc-error-field">{{ errors.first('value.modality') }}</small>
        </transition>
      </div>

      <!-- ORIGEM-->
      <origin-destination-field :show-expo="(value.operation.id === 1 )? true : false"
                                :grey-field="true"
                                :show-impo="(value.operation.id !== 1 )? true : false"
        root-class-name="col-lg-4 col-md-6 form-group px-2"
        v-model="value.origin"
        name="value.origin"
        v-validate="'required_object'"
        :error="errors.first('value.origin')"
        />

      <!--  Destino  -->
      <origin-destination-field :show-expo="(value.operation.id === 1 )? false : true" :grey-field="true" :show-impo="(value.operation.id !== 1 )? false : true"
                                root-class-name="col-lg-4 col-md-6 form-group px-2"
                                v-model="value.destination"
                                name="value.destination"
                                label="filters.filDestino"
                                v-validate="'required_object'"
                                :error="errors.first('value.destination')"
      />
    </div>

    <div class="row custom-row">
      <!--  Incoterm  -->
      <div class="col-lg-2 col-md-6 form-group px-2">
        <label class="small grey-label">{{  $t('form.proposal.incoterm')  }}
          <span><i class="ft-alert-circle"></i><md-tooltip md-direction="right">Em breve mais opções estarão disponíveis</md-tooltip></span>
        </label>
        <v-select
          label="nome" name="value.incoterm" class="grey-field" :clearable="false" :searchable="false"
          v-model="value.incoterm" :options="incotermList" v-validate="{ required_object: true }"
          :disabled="true"
        >
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.nome }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.nome }}
            </div>
          </template>
        </v-select>
        <transition name="slide" mode="">
          <small v-if="errors.first('value.incoterm')" class="royalc-error-field">{{ errors.first('value.incoterm') }}</small>
        </transition>
      </div>

      <!--  Mercadoria  -->
      <div class="col-lg-5 col-md-6 form-group px-2">
        <label class="small grey-label">{{  $t('form.proposal.commoditie')  }}</label>
        <v-select name="value.commoditie" class="grey-field" label="nome" :clearable="false" :searchable="true"
          :filter-by="nomeFilterBy"
          v-model="value.commoditie"  :options="commoditieList" @search="commoditieFetchOptions"
          v-validate="{ required_object: true }"
        >
          <template slot="no-options" slot-scope="{searching, search }">
            <em v-if="!searching" style="opacity: 0.5;">
              Digite para buscar uma mercadoria
            </em>
            <em v-else style="opacity: 0.5;">
              Nenhuma mercadoria encontrada para {{ search }}
            </em>
          </template>
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
            </div>
          </template>
        </v-select>
        <transition name="slide" mode="">
          <small v-if="errors.first('value.commoditie')" class="royalc-error-field">{{ errors.first('value.commoditie') }}</small>
        </transition>
      </div>

      <!--  Equipamentos  -->
      <div v-if="displayEquipamento" class="col-lg-5 col-md-6 form-group px-2">
        <label class="small grey-label">{{  $t('form.proposal.equipments')  }}</label>
        <search-bar-quantity
          name="value.equipments" field-name="descricao" v-model="value.equipments" :options="equipmentList"
        />
        <transition name="slide" mode="">
          <small v-if="equipamentsError" class="royalc-error-field">Campo é obrigatório</small>
        </transition>
      </div>

      <!--  CBM  -->
      <div v-if="displayVolumes" class="col-lg-2 col-md-2 form-group px-2">
        <label class="small grey-label">{{  $t('form.proposal.cbm')  }}</label>
        <custom-input
          v-model="value.cbm"
          name="value.cbm"
          type="text"
          rootClassName="form-group mb-0"
          v-validate="{ required: displayVolumes }"
          :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
          :error="errors.first('value.cbm')"
          >
        </custom-input>
      </div>

      <!--  Peso Bruto  -->
      <div v-if="displayVolumes" class="col-lg-3 col-md-3 form-group px-2">
        <label class="small grey-label">{{  $t('form.proposal.peso_bruto')  }}</label>
        <custom-input
          v-model="value.peso_bruto"
          name="value.peso_bruto"
          type="text"
          rootClassName="form-group mb-0"
          v-validate="{ required: displayVolumes }"
          :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
          :error="errors.first('value.peso_bruto')"
        >
        </custom-input>
      </div>

    </div>

    <div class="row custom-row" >
      <!--  Customer  -->
      <cliente-field
        v-if="isMultipleCustomer"
        :grey-field="true"
        :allowOnlineProposal="true"
        root-class-name="col-lg-8 col-md-12 form-group px-2"
        v-model="value.customer"
        name="value.customer"
        v-validate="'required_object'"
        :error="errors.first('value.customer')"
      />

      <!--  Buscar  -->
      <div class="col-lg-4 form-group mb-6 px-2" :class="searchDivClass">
        <button type="button" class="btn btn-sm btn-primary btn-fill btn-rounded pull-right px-4 py-2"
          @click="onSearch">{{  $t('form.proposal.buscar')  }}</button>
      </div>
    </div>

  </form>
</template>

<script>
// @ is an alias to /src
import { Validator } from 'vee-validate'

// Form
import SearchBarQuantity from './SearchBarQuantity'
import DualSwitch from '../../components/Forms/DualSwitch.vue'
import OriginDestinationField from '@/components/Forms/OriginDestinationField'
import ClienteField from '@/components/Forms/ClienteField'
// import AdvancedDateForm from '@/components/Forms/AdvancedDateForm'
import CustomInput from '@/components/Forms/CustomInput.vue'

// Select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import CommoditieService from '@/services/CommoditieService'
import ProposalService from '@/services/ProposalService'

// Custom object validatorn
Validator.extend('required_object', {
  getMessage: field => 'O campo é obrigatório',
  validate: (value) => {
    if (!value) {
      return false
    }

    if (!value.id || value.id === null) {
      return false
    }

    return true
  }
})

// Custom object validatorn
Validator.extend('required_equipament', {
  getMessage: field => 'O campo é obrigatório',
  validate: (value) => {
    if (!value) {
      return false
    }

    if (value.length <= 0) {
      return false
    }

    return true
  }
})

export default {
  name: 'SearchBar',
  data () {
    return {
      operationOptions: [
        {
          id: 2,
          name: 'IMPORT'
        },
        {
          id: 1,
          name: 'EXPORT'
        }
      ],
      originList: [],
      customerList: [],
      destinationList: [],
      commoditieList: [],
      modalityList: [
        {
          id: 2,
          nome: 'Marítimo - FCL'
        },
        {
          id: 4,
          nome: 'Marítimo - LCL'
        }
      ],
      incotermList: [
        {
          id: 2,
          nome: 'FOB'
        },
        {
          id: 7,
          nome: 'CFR'
        }
      ],
      submitted: false
    }
  },
  components: {
    ClienteField,
    CustomInput,
    SearchBarQuantity,
    vSelect,
    DualSwitch,
    OriginDestinationField
    // AdvancedDateForm
  },
  props: {
    equipmentList: {
      type: [Array],
      description: 'List of equipment',
      default: (val) => {
        val = []
        return val
      }
    },
    fields: {
      type: [Array],
      description: 'Field available to select',
      default: (val) => {
        val = []
        return val
      }
    },
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    onSelectModality (val) {
      if (val && val.id === 2) {
        // Se Maritimo FCL - Limpa dados do LCL
        this.value.cbm = null
        this.value.peso_bruto = null
      } else {
        // Se Maritimo LCL - Limpa dados do equipamento
        this.value.equipments = []

        for (let i in this.equipmentList) {
          let value = this.equipmentList[i]

          let index = this.equipmentList.findIndex((e) => {
            return e.id === value.id
          })

          this.equipmentList[index].quantity = 0
        }
      }
    },
    nomeFilterBy (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    customerFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      ProposalService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
        loading(false)
      })
    },
    originDestinationFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1 || this.$util.slugify(option.sigla).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    /**
     * Triggered when the destination search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */

    /**
     * Triggered when the commoditie search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    commoditieFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CommoditieService.getCommodities(filters).then(res => {
        _this.commoditieList = res.data.data
        loading(false)
      })
    },
    // Equipment
    removeEquipment (value) {
    },
    addEquipment (value) {
    },
    onSearch () {
      let _this = this

      _this.submitted = true

      _this.$validator.validateAll('proposalSearchBar').then((result) => {
        if (result && !_this.equipamentsError) {
          _this.$emit('search', _this.value)
        } else {
          _this.$util.scrollToFirstError()
        }
      })
    },
    equipmentBeforeSelect (value) {
    },
    applyTranslate (val) {
      // Se não informado retorna vazio
      if (!val) {
        return ''
      }

      let translated = this.$i18n.t('taskCampaign.fields.' + val)

      if (translated.includes('taskCampaign.fields.')) {
        return val
      }

      return translated
    },
    setOperation (obj) {
      // Set Incoterm
      if (obj.id === 1) {
        this.value.incoterm = {
          id: 7,
          nome: 'CFR'
        }
      } else {
        this.value.incoterm = {
          id: 2,
          nome: 'FOB'
        }
      }

      if (this.value.operation.id !== obj.id) {
        this.value.origin = {
          id: null,
          nome: null
        }
        this.value.destination = {
          id: null,
          nome: null
        }
      }
      this.value.operation = obj
    }
  },
  computed: {
    allowLcl () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_LCL_QUOTATION_TEST') !== -1) {
        return true
      } else if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      } else if (userInfo && userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        return true
      }
      return false
    },
    displayEquipamento () {
      if (this.value.modality && this.value.modality.id === 2) {
        return true
      }
      return false
    },
    displayVolumes () {
      if (this.value.modality && this.value.modality.id !== 2) {
        return true
      }
      return false
    },
    isPortalTester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_PORTAL_TESTER') !== -1) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isMultipleCustomer () {
      return (this.user && this.user.multiple_customer && this.user.customers_allow_online_proposal_list.length > 1) || this.isSuperAdmin || this.isPortalTester
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    equipamentsError () {
      if (this.displayEquipamento) {
        return this.submitted && this.value.equipments.length <= 0
      }

      return false
    },
    activeConditions () {
      let _this = this

      // Tipos: 1: Texto | 2: Numerico | 3: Data | 4: Opções
      let conditionList = [
        {
          id: 1,
          nome: 'Igual à'
        },
        {
          id: 2,
          nome: 'Diferente de'
        },
        {
          id: 3,
          nome: 'Maior ou igual à',
          tipos: [2, 3]
        },
        {
          id: 4,
          nome: 'Menor ou igual à',
          tipos: [2, 3]
        },
        {
          id: 5,
          nome: 'Contém',
          tipos: [1]
        }
      ]

      return conditionList.filter(function (item) {
        // Se não tiver o atributo é válido para todos
        if (!item.hasOwnProperty('tipos')) {
          return true
        }

        if (_this.value) {
          return item.tipos.includes(_this.value.fieldA.tipo)
        }
      })
    },
    valueFields () {
      let _this = this

      let options = []

      if (_this.value.fieldA && _this.value.fieldA.tipo === 7) {
        options.push({ nome: 'Sim' })
        options.push({ nome: 'Não' })
      }

      return options
    },
    activeFields () {
      let _this = this

      let optionsFields = _this.fields.filter(function (item) {
        // Se não selecionado o campo A não dá opções
        if (!_this.value.fieldA || !_this.value.fieldA.id) {
          return false
        }

        // Se campos forem do mesmo tipo pode selecionar
        if (item.tipo === _this.value.fieldA.tipo) {
          if (item.codigo === 'dia_mes') {
            if (_this.value.condition && (_this.value.condition.id === 1 || _this.value.condition.id === 2)) {
              return true
            } else {
              return false
            }
          } else if (item.codigo === 'nulo') {
            if (_this.value.condition && (_this.value.condition.id === 1 || _this.value.condition.id === 2)) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        }
      })

      return optionsFields
    },
    fieldsList () {
      let _this = this

      let optionsFields = _this.fields.filter(function (item) {
        // Se não selecionado o campo A não dá opções
        if (item.codigo === 'nulo' || item.codigo === 'hoje' || item.codigo === 'dia_mes') {
          return false
        } else {
          return true
        }
      })

      return optionsFields
    },
    searchDivClass () {
      return this.isMultipleCustomer ? 'col-lg-2 btn-m-auto' : 'col-lg-12'
    }
  },
  created () {
    let _this = this

    let filters = {
      'searchTerm': '',
      'per_page': 500
    }

    CommoditieService.getCommodities(filters).then(res => {
      _this.commoditieList = res.data.data
      filters.per_page = 100
      ProposalService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
      })
    })
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  }
}
</script>

<style scoped>
.expansiva {
  margin: -20px;
}

.btn-buscar {
  background-color: #84754E;
  color: #fff;
}

.swal2-popup {
  width: inherit !important;
}

.card-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.md-form-control {
  height: 30px;
}

.elegant-title {
  color: #726752;
  font-weight: 100;
  font-family: Pluto Sans,sans-serif;
}

.grey-label {
  color: gray;
  margin-bottom: 0;
}

.grey-field{
  background: #fff;
  border: none;
  color: gray;
  /* font-variant: small-caps; */
}

.grey-field .selected{
  color: gray;
  text-transform: capitalize;
}

.btn-m-auto {
  margin: auto;
}

@media (max-width: 768px) {
  .btn, .btn-group {
      width:100%;
  }
}
</style>
